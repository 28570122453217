@use '@angular/material'as mat;
@import './light-theme';

$procurant-primary     : mat.get-color-from-palette($procurant-primary);
$procurant-accent      : mat.get-color-from-palette($procurant-accent);
// $procurant-secondary: mat-color($procurant-seo);

$procurant-green : mat.get-color-from-palette($md-freshgreen, 500);
$procurant-yellow: #F2C94C;
$procurant-orange: mat.get-color-from-palette($md-tangerine, 500);
$procurant-red   : #D0021B;

// $procurant-warn: $procurant-orange;
$procurant-success  : $procurant-green;
$procurant-error    : $procurant-red;
$procurant-info     : $procurant-yellow; // #F2C94C;
$procurant-lighttext: #797979;
$procurant-darktext : #797979;

$prc-header-size         : 20px;
$prc-procurant-title-size: 20px;

$prc-smallicon-labelfont: 10px;

//procurant colors

$overlayHeader            : linear-gradient(299deg, #50A449 0%, #1C917E 17.61%, #197B78 37.78%, #155A6F 54.23%, #114267 97.77%);
$overlayRightSideBtn      : #114267;
$overlayRightSideBtnText  : #FFFFFF;
$overlayRightSideBtnBorder: #BDBDBD;

$overlayLeftSideBtn    : #FFFFFF;
$overlayLeftSideBtnText: #005FA4;
$pagePrimaryBtn        : #95CC50;
$pagePrimaryBtnText    : #FFFFFF;
$pagePrimaryBtnBorder  :#BDBDBD;
$pageLinkBtn           : #005FA4;
$pageLinkBtnText       : #FFFFFF;

$procurant-button-primary-light: #114267;
$procurant-general-white       : #FFFFFF;
$procurant-general-swimlane-bg : #ECECEC;
$fonts-disabled                : #BDBDBD;
$blue-gray-50                  : #ECEFF1;
//move line overlay
$white                         : #FFF;
$titleValueColor               : #424242;
$textColor                     : #212121;
$ButtonColor                   : #757575;
$inputFiledBgColor             : #324859;
$white-bg-color                : #e0e0e0;
$light-bg-color                : #078A92;
$procurant-table-column-header : #045A66;
$procurant-error-msg           :#D50000;
$mod-input                     :#FF7D53;
$textBgColor                   :#E3F2FD;
$inputBgColor                  :#EAF5DC;
$statusBlueColor               :#039BE5;
$statusTextcolor               :#F57C00;
$statusGreenColor              :#507D18;
$headerTextColor               :#5C6BC0;
$linkTextColor                 :#212121;
$detailTextColor               :#BF71CC;
$blackColor                    :#000;

//Font Family
$Nunito_Sans :'Nunito Sans';

//font size
$overlayTitle      : 20px;
$overlayHeaderLabel: 16px;
$overlayHeaderValue: 16px;
$tableheader       : 14px;
$gridData          : 14px;
$gridExpand        : 16px;
$gridExpandData    : 14px;
$gridFooterData    : 14px;
$pageFooterTitle   : 14px;
$pageFooterValue   : 14px;
$pageFooterUnit    : 14px;
$headerTitle       : 18px;
$headerLabel       : 14px;
$headerValue       : 14px;
$font_26PX         : 26px;
$font_24PX         : 24px;
$font_22PX         : 22px;
$font_20PX         : 20px;
$font_18PX         : 18px;
$font_16PX         : 16px;
$font_14PX         : 14px;
$font_12PX         : 12px;
$font_10PX         : 10px;
$font_9PX          : 9px;
$font_17PX         : 17px;
$font_28PX         : 28px;
$font_13PX         : 13px;
$font_15PX         : 15px;
$font_11PX         : 11px;

//font weight
$font_weight_300         : 300;
$font_weight_400         : 400;
$font_weight_500         : 500;
$font_weight_600         : 600;
$font_weight_700         : 700;
$overlayTitleWeight      : 400;
$overlayHeaderLabelWeight: 400;
$overlayHeaderValueWeight: 700;
$tableheaderWeight       : 400;
$gridDataWeight          : 400;
$gridInputDataWeight     : 600;
$gridExpandWeight        : 600;
$gridExpandDataWeight    : 400;
$gridFooterDataWeight    : 600;
$pageFooterTitleWeight   : 400;
$pageFooterValueWeight   : 600;
$pageFooterUnitWeight    : 400;
$gridTextWeight          : 600;
$headerTitleWeight       : 700;
$headerLabelWeight       : 400;
$headerValueWeight       : 400;


// SO responsive mixin
// a mixin is different from a function as it does not return a value but serves as placeholder for code
@mixin responsive($breakpoint) {

    /*
        breakpoints are viewport arbitrary values,
        they are defined with the aim of allowing the SCSS/CSS code of your app' behave accordingly to your user's device width,
        the breakpoints I used were inspired by Bootstrap =>
        https://getbootstrap.com/docs/5.0/layout/breakpoints/
    */
    @if $breakpoint==smartphone-portrait {
        @media only screen and (max-width: 575.98px) {
            @content;
        }
    }

    @if $breakpoint==smartphone-landscape {
        @media only screen and (min-width: 575.99px) and (max-width: 767.98px) {
            @content;
        }
    }

    // @if $breakpoint==tablet {
    //     @media only screen and (min-width: 767.99px) and (max-width: 1199.97px) {
    //         @content;
    //     }
    // }

    // @if $breakpoint==laptop {
    //     @media only screen and (min-width: 1199.98px) and (max-width: 1399.98px) {
    //         @content;
    //     }
    // }

    @if $breakpoint==desktop {
        @media only screen and (min-width: 767.99px) and (max-width: 1919.98px) {
            @content;
        }
    }

    @if $breakpoint==large-desktop {
        @media only screen and (min-width: 1919.99px) {
            @content;
        }
    }
}